<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <div class="content-box">
      <el-tabs v-model="activeName">
        <el-tab-pane label="客人信息" name="first">
          <el-row class="search-box">
            <el-row class="search-row">
              <el-row class="search-item">
                <label>姓名：</label>
                <el-input></el-input>
              </el-row>
              <el-button class="bg-gradient" type="primary">搜索</el-button>
              <el-button class="bg-gradient" type="primary">上报公安</el-button>
            </el-row>
          </el-row>
          <el-row class="table-box">
            <el-table :data="guestData" :stripe="true" border fit>
              <el-table-column label="序号">
                <template slot-scope="scope">{{ scope.$index + 1 }}</template>
              </el-table-column>
              <el-table-column label="姓名"></el-table-column>
              <el-table-column label="性别"></el-table-column>
              <el-table-column label="生日"></el-table-column>
              <el-table-column label="民族"></el-table-column>
              <el-table-column label="行政区划"></el-table-column>
              <el-table-column label="国籍"></el-table-column>
              <el-table-column label="地址"></el-table-column>
              <el-table-column label="证件类型"></el-table-column>
              <el-table-column label="证件号"></el-table-column>
              <el-table-column label="入住时间"></el-table-column>
              <el-table-column label="房间号"></el-table-column>
            </el-table>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="上报历史" name="second">
          <el-row class="search-box">
            <el-row class="search-row">
              <el-row class="search-item">
                <label>姓名：</label>
                <el-input></el-input>
              </el-row>
              <el-button class="bg-gradient" type="primary">搜索</el-button>
            </el-row>
          </el-row>
          <el-row class="table-box">
            <el-table :data="guestData" :stripe="true" border fit>
              <el-table-column label="序号">
                <template slot-scope="scope">{{ scope.$index + 1 }}</template>
              </el-table-column>
              <el-table-column label="姓名"></el-table-column>
              <el-table-column label="性别"></el-table-column>
              <el-table-column label="生日"></el-table-column>
              <el-table-column label="民族"></el-table-column>
              <el-table-column label="行政区划"></el-table-column>
              <el-table-column label="国籍"></el-table-column>
              <el-table-column label="地址"></el-table-column>
              <el-table-column label="证件类型"></el-table-column>
              <el-table-column label="证件号"></el-table-column>
              <el-table-column label="入住时间"></el-table-column>
              <el-table-column label="房间号"></el-table-column>
              <el-table-column label="上报状态"></el-table-column>
            </el-table>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      crumbs: new Map([
          ['PMS'], ['公安上报系统']
      ]),
      activeName: 'first',
      loading: false,
      guestData: []
    }
  },
  methods: {

  }
}
</script>
